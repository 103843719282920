import React from "react"
import Layout from "../components/layout"
import EventListing from "../components/EventListing"

export default ({ pageContext: { events }, location }) => {
  // const featuredEvent = events[0]

  return (
    <Layout location={location}>
      <div className="container">
        <h1>Thank you for your purchase!</h1>
        <p>
          Please bring your receipt to the event as it will act as your ticket to the event.
        </p>
      </div>
    </Layout>
  )
}
